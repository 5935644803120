import { Loader } from "../components/Loader";
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useUser from "../core/hooks/useUser";
import { Privileges } from "../core/types/commonType";
import Message from "../page/Message";

const Dashboard = React.lazy(() => import("../page/Dashboard/CategoryCounter"));
const GSList = React.lazy(() => import("../page/GSList"));
const Family = React.lazy(() => import("../page/Family"));
const TransferList = React.lazy(() => import("../page/TransferList"));
const Reports = React.lazy(() => import("../page/Reports"));

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  const { userHasRight } = useUser();
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>{/* <Fallback /> */}</Suspense>
        }
        path="/fallback"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Dashboard />}
          </Suspense>
        }
        path="/dashboard"
      />

      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Family) && <Family />}
          </Suspense>
        }
        path="/family"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Transfer) && <TransferList />}
          </Suspense>
        }
        path="/transfers"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.GSList) && <GSList />}
          </Suspense>
        }
        path="/gs-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.GSList) && <Message />}
          </Suspense>
        }
        path="/message"
      />

      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Reports) && <Reports />}
          </Suspense>
        }
        path="/reports"
      />
      {/* default if nothing matches */}
      <Route element={<Navigate replace to="/dashboard" />} path="*" />
    </Routes>
  );
};

export default AppRoutes;
