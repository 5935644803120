import { appColors } from "../../theme/appColors";
import { palette } from "../../theme/palette";


const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

export const multiSelectStyle = {
  linkButtonProps: {
    color: appColors.reflexBlue[100],
    fontSize: '14px',
    paddingBottom: '10px',
    cursor: 'pointer',
    lineHeight: '16px',
    textAlign: 'left',
    letterSpacing: '0.02em',
    // textDecorationLine: 'underline',
    textTransform: 'none',
  },
  inputProps: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '16px',
    letterSpacing: '0.325px',
    color: '#00c853',
    paddingLeft: '0px',
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  multiSelectBottemButton: {
    textTransform: 'none',
    fontSize: '0.5rem',
    fontWeight: '500',
  },
  menuProps: {},
};

multiSelectStyle.menuProps = {
  list: {
    paddingTop: '5px',
  },
  style: {
    ...multiSelectStyle.inputProps,
    color: '#000000',
    paddingTop: '5px',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
  autoFocus: false,
  disableAutoFocusItem: true,
};

