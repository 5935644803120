import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 * DownArrow icon
 *
 */
function DownArrow({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      width="15"
      height="6"
      viewBox="0 0 16 9"
      {...rest}
      sx={{ width: '15px', marginRight: '10px' }}
    >
      <path
        d="M0.916992 1.07104L1.8598 0.128236L7.98806 6.25649L14.1163 0.128236L15.0591 1.07104L7.98806 8.14211L0.916992 1.07104Z"
        fill="#999999"
      />
    </SvgIcon>
  );
}

export default React.memo(DownArrow);
