import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import {
  IApiResponse,
  IServiceDataApiResponse,
  IServiceDetailsPayload,
  IUser,
  IWeddingHall,
} from "../core/Interface/api.interface";
import { request } from "http";

/**
 * Holds all the API callbacks
 * @returns RTK Implementation for backend
 */

export const AdminApi = createApi({
  reducerPath: "gsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("token", localStorage.getItem("token") || "");
      return headers;
    },
  }),
  tagTypes: [
    "User",
    "UserManagement",
    "dashboard",
    "Service",
    "Serviceposts",
    "Donations",
    "Offers",
    "GS",
    "Family",
    "FamilyMember",
    "Reports",
    "Transfer",
  ],
  endpoints: (builder) => ({
    //Get Report List
    getReports: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/admin/gslist",
          method: "GET",
        };
      },
      providesTags: ["GS"],
    }),

    //Get GS List
    getGS: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: `/admin/gslist`,
          method: "GET",
        };
      },
      providesTags: ["GS"],
    }),

    //Get Family List
    getFamily: builder.query<IApiResponse, number>({
      query: (gsDiv: number) => {
        return {
          url: `/family/list/${gsDiv}`,
          method: "GET",
        };
      },
      providesTags: ["Family"],
    }),

    getMember: builder.query<IApiResponse, number>({
      query: (id) => {
        return {
          url: `/family/view/${id}`,
          method: "GET",
        };
      },
      providesTags: ["FamilyMember"],
    }),

    //Get Transfer List
    getTransfer: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: `/admin/family/transfer/list`,
          method: "GET",
        };
      },
      providesTags: ["Transfer"],
    }),

    addTransfer: builder.mutation<IApiResponse, void>({
      query: (newTransfer) => ({
        url: "/transfers",
        method: "POST",
        body: newTransfer,
      }),
      invalidatesTags: ["Transfer"],
    }),

    getUser: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/user/info",
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["User"],
    }),
    login: builder.mutation<IApiResponse, IUser>({
      query: (request) => {
        return {
          url: "/user/login",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
    }),
    getAllServicePosts: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/serviceposts",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Serviceposts"],
      keepUnusedDataFor: 0,
    }),
    // update service status
    getChangedServiceStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/service/status/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Serviceposts"],
    }),
    // update Service post datas
    updateService: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/service/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Serviceposts"],
    }),
    getRef: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/admin/info",
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["User"],
    }),
    getReference: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/user/reference",
          method: "GET",
        };
      },
    }),
    getReport: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/report",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
    }),
    userRegister: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/user/register",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["GS"],
    }),
    updateStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/family/transfer/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Transfer"]
    }),
     //Get Count Dashboard
     getCount: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/dashboard/info",
          method: "GET",
        };
    },
    providesTags: ["dashboard"],
    }), 

  }),
});

export const {
  useGetReportsQuery,
  useGetGSQuery, //List all gs details
  useGetFamilyQuery, //List all family details
  useGetRefQuery,
  useGetMemberQuery,
  useGetTransferQuery,
  useUpdateStatusMutation,
  useGetCountQuery,

  useGetUserQuery,
  useLoginMutation,
  useGetAllServicePostsQuery, // for list all service posts
  useGetChangedServiceStatusMutation, //for change service post status
  useUpdateServiceMutation, // for update service post datas only
  useGetReferenceQuery,
  useGetReportMutation,
  useUserRegisterMutation,
} = AdminApi;
