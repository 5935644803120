import React from 'react';
import Box from '@mui/material/Box';
import Login from './Login';
import { Grid, Typography } from '@mui/material';
import AnimatedPage from '../../layouts/AnimatedPage';
import { appColors } from '../../theme/appColors';

const Auth = () => {
  return (
    <Box data-testid="Terms and condition Page">
      <Grid
        container
        sx={{

          background: `rgb(26, 31, 41)  url(${'../images/s3.jpg'}) center center`,
          backgroundSize: 'cover',
          // filter: 'blur(1px)'
          
        }}
      >
        <Grid
          item
          lg={8}
          md={7}
          sm={6}
          xs={0}
        >
          <Typography sx={{ fontSize: 30, fontWeight: 900, pl: 4, pt: 10,color: appColors.white , 
           textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000'}}>
            {' '}
            Divisional Secretariats Admin
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={5}
          sm={6}
          xs={12}
        >
          <AnimatedPage>
            <Login />
          </AnimatedPage>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Auth;
