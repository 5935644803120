import React, { useEffect, useMemo, useRef, useState } from "react";
import AnimatedPage from "../../layouts/AnimatedPage";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { appColors } from "../../theme/appColors";
import { useGetUserQuery } from "../appApi";
import MultiSelectCheckbox from "../../components/Checkbox/MultiSelectCheckbox";
import SendIcon from "@mui/icons-material/Send";


export interface IRefProp {
  id: number;
  label: string;
  villages?: {
    id: number;
    label: string;
  }[];
}

export interface IReferenceAPI {
  gsDivisions: IRefProp[];
  occupationReference: IRefProp[];
  genderReference: IRefProp[];
  religionReference: IRefProp[];
  divisionIds?: IRefProp[];
}

const Message: React.FC = () => {
  const [selectGsDivision, setSelectGsDivision] = useState<string>("0");
  const [selectVillage, setSelectVillage] = useState<string>("0");

  const { data: userInfo, isLoading: userInfoLoading } = useGetUserQuery();
  const gsDivision = useMemo(() => {
    let gsd = userInfo && (userInfo?.data as IReferenceAPI).divisionIds;

    return [{ id: 0, label: "All" }, ...(gsd ?? [])];
  }, [userInfo]);

  const villages = useMemo(() => {
    if (gsDivision) {
      const v = gsDivision.filter((f) => f.id === parseInt(selectGsDivision));
      return [{ id: 0, label: "All" }, ...(v[0]?.villages ?? [])];
    }
    return [{ id: 0, label: "All" }];
  }, [gsDivision, selectGsDivision]);

  return (
    <AnimatedPage>
      <Box
        sx={{
          backgroundColor: appColors.greenSmoke,
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 900,
            pb: 2,
          }}
        >
          Send message
        </Typography>
        <Card
          sx={{
            borderRadius: "2px",
            pb: "10px",
            maxWidth: "100%",
            boxShadow: "0 0 10px #ccc;",
          }}
        >
          <CardContent sx={{ m: 0 }}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ padding: "5px" }}
            >
              <Grid item xs={8} md={8} lg={3} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "1rem",
                    letterSpacing: "0.32px",
                    pb: 1,
                  }}
                >
                  Select GS Division
                </Typography>

                <FormControl sx={{ width: `100%` }}>
                  <MultiSelectCheckbox
                    isSearchEnable={false}
                    list={
                      (gsDivision &&
                        gsDivision.map((d) => ({
                          name: d.label,
                          value: d.id.toString(),
                        }))) ||
                      []
                    }
                    value={selectGsDivision}
                    onChange={(val) => {
                      setSelectVillage("0");
                      setSelectGsDivision(val.target.value);
                    }}
                    name={"gs_division"}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8} md={8} lg={3} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "1rem",
                    letterSpacing: "0.32px",
                    pb: 1,
                  }}
                >
                  Select Village
                </Typography>
                <FormControl fullWidth sx={{ width: `100%` }}>
                  <MultiSelectCheckbox
                    isSearchEnable={false}
                    list={
                      (villages &&
                        villages.map((d) => ({
                          name: d.label,
                          value: d.id.toString(),
                        }))) ||
                      []
                    }
                    onChange={(val) => {
                      setSelectVillage(val.target.value);
                    }}
                    value={selectVillage}
                    name={"village"}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} sm={6}>
                {/* <TextField
                  placeholder="MultiLine with rows: 2 and rowsMax: 4"
                  multiline
                  rows={2}
                  maxRows={4}
                  fullWidth
                /> */}
              </Grid>

              <Grid item xs={12} md={12} lg={6} sm={6}>
              <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "1rem",
                    letterSpacing: "0.32px",
                    pb: 1,
                  }}
                >
                  Message
                </Typography>
                <TextField
                  placeholder="Type your message"
                  multiline
                  rows={4}
                  maxRows={6}
                  fullWidth
                />
              </Grid>
             
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                pt: 2
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  background: appColors.reflexBlue[100],
                  color: appColors.white,
                  height: "40px",
                  width: "auto",
                }}
                // disabled={isFetchingReport}
                endIcon={<SendIcon />}
                // onClick={filterReport}
              >
                {false && (
                  <Box sx={{ pr: 2, pt: 0.5 }}>
                    <CircularProgress size={16} sx={{ color: "white" }} />
                  </Box>
                )}{" "}
                Send
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </AnimatedPage>
  );
};

export default Message;
