import { Box, IconButton, ListSubheader, TextField } from "@mui/material";
import { MultiSelectSearchProps } from "./MultiSelectProps";
import React from "react";
import { multiSelectStyle } from "./MultiSelectStyle";
import Search from "./Search";

const MultiSelectSearch = ({ text, search }: MultiSelectSearchProps) => {
  const handleSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <ListSubheader
      sx={{
        width: "100%",
        borderRadius: '8px',
        height: "60px",
        padding: '3px',
      }}
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        sx={{
          ...multiSelectStyle.inputProps,
          width: "100%",
          padding: '5px',
          borderRadius: '8px', 
          backgroundColor: 'white',
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
        InputProps={{
          startAdornment: (
            <IconButton onClick={handleSearchClick}>
              <Search></Search>
            </IconButton>
          ),
        }}
        value={text}
        placeholder="Search"
        onChange={search}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};

export default React.memo(MultiSelectSearch);
