import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { MultiSelectProp } from "./MultiSelectProps";
import { multiSelectStyle } from "./MultiSelectStyle";
import DownArrow from "./DownArrow";
import MultiSelectSearch from "./MultiSelectSearch";
import DoneIcon from "@mui/icons-material/Done";
import { theme } from "../../theme";
import { appColors } from "../../theme/appColors";
import { rem } from "../../theme/typography";

// MultiSelectCheckbox reusable component for single select value
const MultiSelectCheckbox = ({
  isSearchEnable = true,
  list,
  onChange,
  selectedVals,
  lable,
  name,
  value,
}: MultiSelectProp) => {
  const [opening, setOpening] = React.useState(false);
  const [valuesList, setValuesList] = React.useState(list);

  useEffect(() => {
    setValuesList(list);
  }, [list]);

  // Input search
  const [searchText, setSearchText] = React.useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    const updatedList = list.filter((filteredResult) =>
      filteredResult.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setValuesList(updatedList);
  };

  const openSelect = () => {
    setOpening(true);
  };

  const closeSelect = () => {
    setOpening(false);
  };

  const inputComponent = {
    height: `${theme.spacing(30.25)}px`,
    backgroundColor: 'white',
    width: '100%',
    paddingRight: `${theme.spacing(1.5)}px`,
    paddingLeft: `${theme.spacing(1.5)}px`,
    paddingTop: `${theme.spacing(1.5)}px`,
    paddingBottom: `${theme.spacing(1.5)}px`,
    '& .MuiOutlinedInput-notchedOutline': {
      // borderWidth: 0,
    },
    '& .MuiOutlinedInput-input': {
      // borderWidth: 0,
      // paddingTop: 1,
    },
    fontSize: rem(14),
    lineHeight: `${theme.spacing(2)}px`,
    letterSpacing: '0.325px',
    // color: '#808080',
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '40px',
      paddingLeft: '0px',
      color: appColors.black[60],
      // backgroundColor: appColors.royalBlue[60],
    },
  };

  return (
    <TextField
      select
      fullWidth
      name={name}
      // value={!isSearchEnable && valuesList.length > 0 ? valuesList[0].value : selectedVals}
      value={value}
      // value={selectedVals}
      onChange={onChange}
      onClick={openSelect}
      onBlur={closeSelect}
      // label={lable}
      variant="outlined"
      SelectProps={{
        MenuProps: {
          ...multiSelectStyle.menuProps,
        },
        IconComponent: DownArrow,
      }}
      sx={inputComponent}
    >
      {isSearchEnable && (
        <Box
          sx={{
            display: "flex",
            position: "sticky",
            bottom: "0px",
            height: "65px",
            padding: "5px",
          }}
        >
          <MultiSelectSearch text={searchText} search={handleSearch} />
        </Box>
      )}

      <Divider />

      {valuesList.length === 0 && (
        <Typography align="center" data-testid="predictive-list-no-result">
          No search results found
        </Typography>
      )}
      {valuesList.map((data) => (
        <MenuItem key={data.name} value={data.value}>
          {data.name}
          {selectedVals === data.value && <DoneIcon />}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default React.memo(MultiSelectCheckbox);
