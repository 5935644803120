import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 * DownArrow icon
 *
 */
function Search({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon width="24" height="23" viewBox="0 0 24 23" fill="none" sx={{ fill: 'none' }} {...rest}>
      <path d="M18.8319 18.3189L14.0098 13.5008" stroke="#999999" />
      <path
        d="M9.44928 15.3878C13.0111 15.3878 15.8986 12.5028 15.8986 8.94391C15.8986 5.38504 13.0111 2.5 9.44928 2.5C5.88744 2.5 3 5.38504 3 8.94391C3 12.5028 5.88744 15.3878 9.44928 15.3878Z"
        stroke="#999999"
      />
    </SvgIcon>
  );
}

export default React.memo(Search);
