import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, SvgIconProps, Tooltip, useMediaQuery } from "@mui/material";
import { Privileges } from "../../core/types/commonType";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useUser from "../../core/hooks/useUser";
import { appColors } from "../../theme/appColors";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../../page/appApi";
import { useMemo } from "react";
import { IUser } from "../../core/Interface/api.interface";
import Logout from "../../icons/Logout";
import useApp from "../../utils/useApp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Toolbar from "@mui/material/Toolbar";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuList: {
  title: string;
  icon: SvgIconProps;
  path: string;
  newPage?: boolean;
  privilege: string;
}[] = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    privilege: Privileges.Dashboard,
  },
  {
    title: "GS Management",
    icon: <ListAltIcon />,
    path: "/gs-management",
    privilege: Privileges.GSList,
  },
  {
    title: "Family",
    icon: <FamilyRestroomIcon />,
    path: "/family",
    privilege: Privileges.Family,
  },
  {
    title: "Transfers",
    icon: <TransferWithinAStationIcon />,
    path: "/transfers",
    privilege: Privileges.Transfer,
  },
  {
    title: "Reports",
    icon: <AnalyticsIcon />,
    path: "/reports",
    privilege: Privileges.Reports,
  },
  {
    title: "Message",
    icon: <ForwardToInboxIcon />,
    path: "/message",
    privilege: Privileges.Reports,
  },
];

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { userHasRight } = useUser();
  const navigate = useNavigate();
  const { currentMenuName } = useApp();
  const { data, isLoading } = useGetUserQuery();

  const userName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUser
      return userData?.firstname;
    }
    return false;
  }, [data]);

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <>
              <Box alignItems="left" sx={{ pr: 4, pt: 1 }}>
                <img
                  height={40}
                  width={50}
                  src="logo.png" //http link-s
                />
              </Box>

              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ color: appColors.white, overflow: "visible" }}
              >
                GS Division Management
              </Typography>
            </>
          )}
          <Grid
            container
            data-testid="user-content"
            spacing={3}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: appColors.white,
                  fontWeight: 600,
                }}
              >
                {userName}
              </Typography>
              {/* <Typography
                noWrap
                sx={{fontSize: "0.7rem",
                  color: appColors.white,
                  fontWeight: 600,}}
              >
                <Box sx={{ pr: 1 }} component="span">
                  Last login:
                </Box>
                2323
              </Typography> */}
            </Grid>

            <Grid item>
              <Logout
                data-testid="logout-icon"
                sx={{
                  verticalAlign: "middle",
                  color: appColors.white,
                }}
                onClick={logout}
              />
            </Grid>
          </Grid>
          
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{ pt: 0, pr: 0 }}>
            <img
              height={40}
              width={50}
              src="logo.png" //http link-s
            />
          </Box>
          <Typography
            sx={{ color: appColors.amberYellow[100], pr: 6, fontWeight: 800 }}
          >
            DS Admin
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuList.map(
            (ml) =>
              userHasRight(ml.privilege) && (
                <ListItem
                  key={ml.title}
                  disablePadding
                  sx={{
                    display: "block",
                    borderRight:
                      currentMenuName === ml.path
                        ? `4px solid ${appColors.royalBlue[100]}`
                        : "none",
                    color: appColors.black,
                    background:
                      currentMenuName === ml.path
                        ? appColors.greenSmoke[20]
                        : "none",
                  }}
                >
                  <Tooltip title={ml.title} placement="right-start" arrow>
                    <ListItemButton
                      onClick={() => {
                        if (isSmall) {
                          // setDrawer(!openDrawer);
                        }
                        if (ml.newPage) {
                          window.open(ml.path, "_blank");
                        } else {
                          navigate(ml.path);
                        }
                      }}
                      sx={{
                        minHeight: 40,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: appColors.black,
                        }}
                      >
                        {ml.icon as React.ReactNode}
                      </ListItemIcon>
                      <ListItemText
                        secondary={ml.title}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
