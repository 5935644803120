import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "./en/common.json";
import { default as zsCommon } from "./zs/common.json";
import enHome from "./en/home.json";
import { default as zsHome } from "./zs/home.json";
import { default as enDriver } from "./en/driver.json";
import { default as enVehicle } from "./en/vehicle.json";
import enUserManage from "./en/user.json";

export const defaultNS = "common";
export const resources = {
  en: {
    common: enCommon,
    home: enHome,
    driver: enDriver,
    vehicle: enVehicle,
    usermanage: enUserManage,
  },
  zs: {
    common: zsCommon,
    home: zsHome,
    driver: enDriver,
    vehicle: enVehicle,
    usermanage: enUserManage,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  ns: ["home", "common", "driver", "vehicle", "usermanage"],
  defaultNS,
  resources,
});

// Change language functionality
// i18n.changeLanguage('zs');
export default i18n;
