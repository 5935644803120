import React, { useEffect } from 'react';
import { MainLayoutProps } from '../../core/Interface/layout.interface';
import { useGetUserQuery } from '../appApi';
import { useAppDispatch } from '../../store/hooks';
import { setEnableAuth } from '../Auth/AuthSlice';
/**
 * Main layout which will load the content with the children
 * @param children - Component
 */
const MainLayout = ({ children }: MainLayoutProps) => {
  const dispatch = useAppDispatch();
  const { data, isError, isLoading, isFetching } = useGetUserQuery();
  useEffect(() => {
    if (data?.code === 200) {
      dispatch(setEnableAuth());
    }
  }, [data?.code, dispatch]);
  return (
    <>
    {children}
      {/* {!data && <>{children}</>}
      {!data && <UnAuthorised data-testid="unautorised-fallback" />} */}
    </>
  );
};

export default React.memo(MainLayout);
